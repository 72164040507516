<template>
  <div class="DemoVideo-list">
    <GlobalInfoBar
        title="演示视频"
        content="回收商家及门店商家小程序视频管理"
    />
    <GlobalChunk
        icon="search"
        title="筛选查询"
    >
      <div class="button_top">
        <div class="radiobtn">
          <div class="faultBtn">
              <div v-bind:class="this.clickShow=== '01'? 'left' : 'right'" @click="BtnClick('active')">
                回收商家视频
              </div>
              <div v-bind:class="this.clickShow=== '02'? 'left' : 'right'" @click="BtnClick('before')">
                门店商家视频
              </div>
          </div>
        </div>
      </div>
      <div slot="filter">
        <StaffManageFrom :clickShow="clickShow" />
      </div>
      <StaffManageTable :clickShow="clickShow" />
    </GlobalChunk>
  </div>
</template>

<script>
// import _api from "@/utils/request";
import StaffManageFrom from './From'
import StaffManageTable from './Table'
export default {
  name: 'StaffManage-list',
  components: {StaffManageFrom,StaffManageTable},
  data(){
    return{
      clickShow:'01',
      seachDataList:[],
    }
  },
  methods:{
    BtnClick(type){
      if(type==='active'){
        this.clickShow='01'
      }else{
        this.clickShow='02'
      }
      
      const baseRequest = {
        startTime:"",
        endTime: "",
        isEnable: "",
        pageNum: 1,
        pageSize: 10,
        useType:this.clickShow
      };
      this.$store.commit('tagsView/SEACH_PARAMS',baseRequest)
    }
  }
}
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.DemoVideo-list{
  .button_top{
    margin-bottom: 20px;;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .faultBtn{
    margin-bottom: 40px ;
    width: 280px;
    background: #F9FBFD;
    border: 1px solid #C1D1FF;
    box-shadow: 0px 4px 8px 0px rgba(196, 197, 198, 0.2);
    border-radius: 16px;
    display: flex;
    cursor: pointer;
    .left{
      text-align: center;
      color: white;
      width: 50%;
      font-size: 14px;
      font-family: FZLanTingHei-M-GBK;
      font-weight: 400;
      line-height: 34px;
      height: 32px;
      background: #0981FF;
      border-radius: 16px;
    }
    .right{
      text-align: center;
      color: #333;
      width: 50%;
      font-size: 14px;
      font-family: FZLanTingHei-M-GBK;
      font-weight: 400;
      line-height: 34px;
      height: 32px;
      border-radius: 16px;
    }
  }
}
</style>

<template>
  <div class="GlobalFormDemo">
    <GlobalForm
      :init-data="initData"
      :form-rules="formRules"
      :form-item-list="formItemList"
      :inline="true"
      :needBtnLoading="true"
      round
      @handleConfirm="handleConfirm"
      confirmBtnName="查询"
    >
        <div style="text-align: right;margin-bottom: 20px" slot="AddSlot">
            <el-button size="small" type="primary" icon="el-icon-plus" @click="$router.push('/DemoVideo/add')">添加视频</el-button>
        </div>
    </GlobalForm>
  </div>
</template>

<script>
import {formValidateMobile} from '@/utils/form-validate'
// import _api from "@/utils/request";
export default {
  name: 'Form',
  props:["clickShow"],
  data() {
    return {
      title:"",
      startTime:"",
      endTime:"",
      isEnable:"",
      initData: null,
      formItemList: [
        {
          key: 'title',
          type: 'input',
          labelName: '视频标题',
          placeholder: '请输入视频标题'
        },
        {
          key: 'isEnable',
          type: 'select',
          labelName: '视频状态',
          placeholder: '请选择视频状态',
          option:[{
            value:'01',
            label:"上架",
          },
          {
            value:'00',
            label:"下架",
          },
          ]
        },
        {
          key: 'entryTime',
          type: 'daterange',
          labelName: '上传时间',
          placeholder: '请选择上传时间',
          valueFormat:"timestamp"
        },
        {slot:"AddSlot"}
      ],
      formRules: {
        mobile: [{
          validator: formValidateMobile,
          trigger: 'blur'
        }]
      }
    }
  },
  // created(){
  //   this.handleConfirm()
  // },
  methods: {
    //查询
    handleConfirm(data,cd) {
      console.log(data)
      if(data){
        if(data.entryTime===null){
        this.startTime="",
        this.endTime="",
        this.isEnable=data.isEnable
        this.title=data.title
        }else{
          this.isEnable=data.isEnable
          this.title=data.title,
          this.startTime=data.entryTime[0],
          this.endTime=data.entryTime[1]
        }
      }else{
        this.startTime="",
        this.endTime="",
        this.isEnable="",
        this.title=""
      }
      console.log(this.endTime)
      const baseRequest = {
        title: this.title,
        startTime: this.startTime,
        endTime: this.endTime,
        isEnable: this.isEnable,
        useType:"",
        pageNum:1,
        pageSize:10,
        disuseLoding: () => {
          if (typeof cd === 'function') {
            cd()
          }
        },
      } 
        this.$store.commit('tagsView/SEACH_PARAMS',baseRequest)
    }
  }
}
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.GlobalFormDemo{}
</style>

<template>
  <div class="GlobalTable">
    <GlobalTable
      ref="GlobalTable"
      v-loading="loading"
      :columns="tableColumns"
      :data="this.seachDataList"
      :currentPage="page.pageNum"
      :total="this.page.total"
      @handleCurrentChange="handleCurrentChange"
    >
      <el-table-column label="排序" slot="operation" align="center">
        <template slot-scope="{ row }">
          <el-input-number size="small" v-model="row.sort" @change="handleChangeNum(row)"></el-input-number>
        </template>
      </el-table-column>
      <el-table-column label="视频封面" slot="operation" align="center">
        <template slot-scope="{ row }">
          <span>
            <img :src="row.coverImg" width="40" height="40" />
          </span>
        </template>
      </el-table-column>
      <el-table-column label="设为首屏" slot="operation" align="center">
        <template slot-scope="{ row }">
          <el-switch
            inactive-color="#bebebe"
            @change="handleSwitch(row,'switchScreen')"
            v-model="row.isHome"
          ></el-switch>
        </template>
      </el-table-column>
      <el-table-column label="上下架" slot="operation" align="center">
        <template slot-scope="{ row }">
          <el-switch
            inactive-color="#bebebe"
            @change="handleSwitch(row,'switchDropOff')"
            v-model="row.isEnable"
          ></el-switch>
        </template>
      </el-table-column>
      <el-table-column width="200" label="操作" slot="operation" align="center">
        <template slot-scope="{ row }">
          <el-button size="mini" type="primary" round @click="handleBtn(row, 'edit')">编辑</el-button>
          <el-button size="mini" type="danger" round @click="handleBtn(row, 'deleted')">删除</el-button>
        </template>
      </el-table-column>
    </GlobalTable>
    <!--弹框-->
     <el-dialog
      :title="dialogTitle"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      width="380px"
      @closed="handleDialogClosed"
    >
      <div style="font-size:16px;color:#666666;text-align:center;height:100px;line-height:100px">{{this.dialongText}}</div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click.native="handleSuccess">确定</el-button>
        <el-button @click.native="handleClose">取消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import MinXinRequest from "@/mixins/request";
import _api from "@/utils/request";
export default {
  name: "Table",
  mixins: [MinXinRequest],
  props:["clickShow"],
  data() {
    return {
      seachDataList: [],
      StaffEnable: {
        id: "",
        isEnable: Boolean
      },
      StaffHome:{
        id: "",
        isHome: Boolean
      },
      //弹窗
      dialongText:"",
      dialogVisible: false, // 弹框的出现与否
      dialogTitle: "", // 标题
      dialongDecide: "",
      deletedId: "", //密码重置ID
      tableColumns: [
        { label: "视频标题", prop: "title" },
        { label: "视频副标题", prop: "viceTitle" },
        { label: "播放量", prop: "viewed" },
        { label: "添加时间", prop: "createTime" },
        { slotName: "operation" }
      ]
    };
  },
  computed: {
    SeachParams() {
      return this.$store.state.tagsView.SeachParams
    },
  },
  created() {
    this.handleCurrentChange();
  },
  watch:{
    SeachParams(newVal){
      console.log(newVal)
      this.handleCurrentChange()
    }
  },
  methods: {
    // 表格切换页面
    handleCurrentChange(val) {
      const baseRequest = {
        title: this.SeachParams.title,
        startTime: this.SeachParams.startTime,
        endTime: this.SeachParams.endTime,
        isEnable: this.SeachParams.isEnable,
        pageNum: val,
        pageSize: this.SeachParams.pageSize,
        useType:this.clickShow 
      };
      _api.domeVideoList(baseRequest).then(res => {
        if (res.code === 1) {
          this.seachDataList = res.data.records;
          this.page.total = res.data.total;
          this.page.pageNum = res.data.current;
          // this.$store.commit("tagsView/POST_STAFF", this.seachDataList);
        }
      }).finally(() => {
        if (typeof this.SeachParams.disuseLoding === 'function') {
          this.SeachParams.disuseLoding();
        }
      });
    },
    // 表格按钮操作
    handleBtn(row, type) {
      console.log("表格操作", type, row);
      if (type === "deleted") {
        this.dialogVisible = true;
        this.dialogTitle = "删除";
        this.dialongText = '删除后，数据将无法恢复，确认继续？'
        this.dialongDecide = "deleted";
        this.deletedId = row.id;
      }else if(type === "edit"){
        this.$router.push({path:"/DemoVideo/add",query:{type: 'edit',id: row.id}})
      }
    },
    //商品上下架
    handleSwitch(row,type) {
      this.dialogVisible = true;
      this.dialongText = '确认要执行该操作吗？'
      if(type ==='switchDropOff'){
        this.dialogTitle = "上/下架";
        this.dialongDecide = "switchDropOff";
        this.StaffEnable.id = row.id;
        this.StaffEnable.isEnable = row.isEnable;
        console.log(row.isEnable)
      }else if(type ==='switchScreen'){
        this.dialongDecide = "switchScreen";
        this.dialogTitle = "设置首屏";
        this.StaffHome.id = row.id;
        this.StaffHome.isHome = row.isHome;
      }
    },
    handleChangeNum(val){
      console.log(val)
      const params ={
        id:val.id,
        sort:val.sort
      }
      _api.updateSort(params).then(res=>{
        if(res.code===1){
          this.$message.success(res.msg)
        }else{
          this.$message.error(res.msg)
        }
      })
    },
    //弹窗取消
    handleDialogClosed() {
      this.dialogVisible = false;
      this.handleCurrentChange();
    },
    //弹窗确定
    handleSuccess() {
      //上下架
      if (this.dialongDecide === "switchDropOff") {
        _api.updateIsEnable(this.StaffEnable).then(res => {
          if (res.code === 1) {
            this.$message.success("操作成功");
          } else {
            this.$message.error("操作失败");
          }
        });
      } 
      //是否首页
      else if(this.dialongDecide === "switchScreen"){
         _api.updateIsHome(this.StaffHome).then(res => {
          if (res.code === 1) {
            this.$message.success(res.msg);
          } else {
            this.$message.error(res.msg);
          }
        });
      }
      //删除
      else if(this.dialongDecide === "deleted"){
        _api.VedioDelete({ id: this.deletedId }).then(res => {
          if (res.code === 1) {
            this.$message.success(res.msg);
          } else {
            this.$message.error(res.msg);
          }
        });
      }
      this.dialogVisible = false;
    },
    handleClose() {
      this.dialogVisible = false;
    }
  }
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.GlobalTable {
}
</style>
